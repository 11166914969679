<template>
  <div>
    <div class="container">
      
      <div class="list list3">
        <div class="listitem1">
          <div class="listbox">
            <div class="name">{{name}}</div>
            <div class="unames">{{mobile}}</div>
            <div class="unames1">{{idnum}}</div>
          </div>
          <div class="listbox1">
            <img :src="imgurl"  class="iconkg" />
          </div>
          
        </div>
      </div>
      <div class="list list2">
        <div class="listitem" >
          <span class="sp">加油数量：</span>
          <input type="number" v-model="rise"  class="input" placeholder="单位升（选填）" />
        </div>
      </div>
      <div class="list list2">
        <div class="listitem" >
          <span class="sp">加油金额：</span>
          <input type="number" v-model="money"  class="input" placeholder="单位元（选填）" />
        </div>
      </div>
      <div class="list list2">
        <div class="listitem" >
          <span class="sp">选择油号：</span>
          <select class="form_select" v-model="selectItemYh" @change="selectYhFn($event)">>
          <!--选择项的value值默认选择项文本 可动态绑定选择项的value值 更改v-model指令绑定数据-->
              <option v-for="itemYh in itemsYh" :key="itemYh.code" :value="itemYh.code">{{itemYh.val}}</option>
          </select>
        </div>
      </div>
      <div class="list list2">
        <div class="listitem" >
          <span class="sp">选择容器：</span>
          <select class="form_select" v-model="selectItem" @change="selectFn($event)">
          <!--选择项的value值默认选择项文本 可动态绑定选择项的value值 更改v-model指令绑定数据-->
              <option v-for="item in items" :key="item.code" :value="item.code">{{item.val}}</option>
          </select>
        </div>
      </div>
      
      <div class="list_ll list2">
        <div class="ll">
          <div class="button primary mm" @click="next">提交</div>    
          <div class="button1 primary mt" @click="goback">取消</div>     
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant';
import qs from 'qs';
import { isEmpty, isTel } from '@/utils';
export default {
  async created() {
    this.token_id = this.$route.query.token_id;
    let openid = this.$route.query.openId;
    window.sessionStorage.setItem('openid', openid);
    this.query();
    this.queryYt();
    this.queryYh();
  },
  data() {
    return {
      token_id: '',
      imgurl:'',
      name:'',
      idnum:'',
      mobile:'',
      money:'',
      rise:'',
      oilname:'',
      bucketname:'',
      state:false,
      selectItem: '',
      items: [
        /*{code:1, val: '公用金属桶'},
        {code:2, val: '自带金属桶'},
        {code:3, val: '自带塑料桶'}*/
      ],
      selectItemYh: '',
      itemsYh: [
        /*{code:1, val: '92#'},
        {code:2, val: '95#'},
        {code:3, val: '98#'}*/
      ]
    };
  },
  methods: {
    selectFn(e) {      
      this.bucketname = this.items[e.target.selectedIndex].val;
      Toast(this.bucketname); 
      // console.log(e)
      // console.log(e.target.selectedIndex) // 选择项的index索引
      // console.log(e.target.value) // 选择项的value
    },
    selectYhFn(e) {
      this.oilname = this.itemsYh[e.target.selectedIndex].val;
      Toast(this.oilname); 
      // console.log(e)
      // console.log(e.target.selectedIndex) // 选择项的index索引
      // console.log(e.target.value) // 选择项的value
    },
    goback() {
      window.WeixinJSBridge.invoke('closeWindow',{},function(res){});
    },
    async query() {
      let { data } = await this.axios.post(
        '/ctid/JY_ITlService/jy_xxjk',
        qs.stringify({
          token_id:this.token_id
        })
      );
      if (data.flag === '0') {
        var test = data.data.replace(/-/g, "+").replace(/_/g, "/");
        var testobj = JSON.parse(decodeURIComponent(escape(window.atob(test.split('.')[1])))).data;  

        this.name = testobj.user_name;
        this.mobile = testobj.mobile;   
        this.idnum = testobj.certificate_code;   
        this.imgurl = testobj.picture;  

      }else{
        Toast(data.message);
      }
    },
    async queryYt() {
      let { data } = await this.axios.get('/ctid/JY_ITlService/jy_selsitedic');
      var test = data.data.replace(/-/g, "+").replace(/_/g, "/");
      this.items = JSON.parse(decodeURIComponent(escape(window.atob(test.split('.')[1])))).data;
      this.selectItem = this.items[0].code;
      this.bucketname = this.items[0].val;
    },
    async queryYh() {
      let { data } = await this.axios.get('/ctid/JY_ITlService/jy_seloildic');
      var test = data.data.replace(/-/g, "+").replace(/_/g, "/");
      this.itemsYh = JSON.parse(decodeURIComponent(escape(window.atob(test.split('.')[1])))).data;
      this.selectItemYh = this.itemsYh[0].code;
      this.oilname = this.itemsYh[0].val;
    },
    async next() {
      //Dialog({ message: "name："+ this.name+",idnum"+ this.idnum+",wxdj_uuid"+ this.wxdj_uuid+",picture_peo"+ this.picture_peo});
      if(this.state){
        return false;
      }
      this.state = true;
      if (isEmpty(this.rise)&&isEmpty(this.money)) {
        Toast('数量和金额至少填一个!');
        this.state = false;
        return;
      }
      let { data } = await this.axios.post(
        '/ctid/JY_ITlService/jy_xxtj',
        qs.stringify({ 
          money: this.money, 
          oill: this.rise, 
          onum: this.selectItemYh, 
          sdic: this.selectItem,  
          token_id: this.token_id 
        })
      );
      if (data.flag === '0') {  
        this.state = false;
        Dialog.confirm({
          showCancelButton: false,
          message: data.message+"，退出操作页面！"
        }).then(async () => {
          window.WeixinJSBridge.invoke('closeWindow',{},function(res){});
        });
      }else {
        this.state = false;
        Toast(data.message); 
      } 
      
      
    }
    
  }
  
};
</script>

<style lang="less" scoped>
.icon1 {
  width: 64px;
  height: 64px;
  display: block;
}
.bg {
  height: 503px;
}
.content {
  margin-top: 60px;
}
.list {
  width: 710px;
  margin: 0px auto;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  &.list2 {
    margin-top: 20px;
  }
  &.list3 {
    margin-top: 50px;
  }
  .ll {
    width: 60%;
  }
  .listitem {
    overflow: hidden;
    height: 100px;
    border-radius: 8px;
    .icon {
      width: 64px;
      margin: 18px 0 18px 40px;
      display: block;
      float: left;
    }   
    .arrow {
      float: right;
      display: block;
      margin-top: 32px;
      margin-right: 38px;
      height: 40px;
    }
    .text {
      font-size: 32px;
      float: left;
      margin-left: 20px;
      line-height: 44px;
      margin-top: 30px;
    }
    .right {
      line-height: 40px;
      color: #666666;
      font-size: 28px;
      float: right;
      margin-top: 32px;
      margin-right: 38px;
    }
    .input {
      margin-left: 15px;
      border: 0;
      color: rgb(136, 134, 134);
      margin-top: 34px;
      font-size: 28px;
      width: 460px;
      float: right;
      margin-right: 34px;
    }
    .sp {
      margin-left: 34px;
      font-size: 28px;
      width: 200px;
      height: 100px;
      line-height: 100px;
      padding-top: 90px;
    }
    .form_select {
      /* 清除默认边框 */
      border: 0;
      /* 清除默认的箭头样式 */
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      /* 右侧添加小箭头的背景图 */
      background: url('/img/arrow_1.png') 439px center no-repeat;
      background-size: 26px;
      width: 460px;
      height: 60px;
      background-color: transparent;
      font-size: 28px;
      color: rgb(136, 134, 134);
      line-height: 60px;
      //vertical-align: middle;
      //direction: rtl;
      padding-right: 40px;
      float: right;
      margin-right: 34px;
      margin-top: 20px;
    } 
    select:focus { outline: none; }
  }
  .listitem1 {
    overflow: hidden;
    height: 270px;
    border-radius: 8px;
    display: flex;
    .listbox {
      flex: 1;
      overflow: hidden;
      .red {
        color: rgb(180, 4, 4);
      }
      .name {
        height: 60px;
        line-height: 60px;
        overflow: hidden;
        font-size: 32px;
        font-weight:800;
        margin-top: 52px;
        margin-left: 34px;
      }
      .unames {
        height: 30px;
        line-height: 30px;
        font-size: 28px;
        color: rgb(165, 165, 165);
        margin-top: 30px;
        margin-left: 36px;
      }
      .unames1 {
        height: 30px;
        line-height: 30px;
        font-size: 28px;
        color: rgb(165, 165, 165);
        margin-top: 10px;
        margin-left: 36px;
      }
      
    }
    .listbox1 {
      flex: 1;
      overflow: hidden;
      .iconkg {
        margin-top: 34px;
        width: 168px;  
        height: 205px;   
        float: right;
        margin-right: 34px;
      }

      
    }
    
  }
}
.list_ll {
  width: 710px;
  margin: 0px auto;
  border-radius: 8px;
  overflow: hidden;
  &.list2 {
    margin-top: 15px;
  }
  .ll {
    width: 90%;
    margin: 0px auto;
  }
  
}
.info {
  overflow: hidden;
  margin: 90px 54px 62px;
  img {
    width: 144px;
    height: 144px;
    border-radius: 144px;
    display: block;
    float: left;
  }
  .detail {
    overflow: hidden;
    margin-left: 44px;
    float: left;
    .name {
      margin-top: 20px;
      line-height: 66px;
      color: #fff;
      font-size: 48px;
    }
    .tel {
      height: 40px;
      font-size: 28px;
      font-weight: 400;
      line-height: 40px;
      color: rgba(255, 255, 255, 1);
      opacity: 0.6;
    }
  }
}
.button1 {
  width: 100%;
  height: 88px;
  text-align: center;
  overflow: hidden;
  font-size: 32px;
  line-height: 88px;
  color: #000000;
  background: rgba(238, 238, 238, 1);
  border-radius: 8px;
  cursor: pointer;
  &.primary {
    background: linear-gradient(90deg, rgba(31, 214, 199, 1) 0%, rgba(16, 189, 241, 1) 100%);
    color: #fff;
  }
  &:active {
    opacity: 0.8;
  }
  &.mb {
    margin-top: 54px;
  }
  &.mt {
    margin-top: 20px;
  }
}
</style>